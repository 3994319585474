<template>
  <section class="charge">
    <el-row :gutter="10">
      <el-col :lg="24" :md="12" :sm="24" :xs="24">
        <el-input
          v-model="clientQuery"
          :autofocus="true"
          size="mini"
          name="client-search"
          auto-complete="false"
          aria-autocomplete="false"
          placeholder="Buscar"
        />
      </el-col>
    </el-row>
    <el-row :gutter="10" type="flex" justify="center">
      <el-col :lg="24" :md="12" :sm="24" :xs="12">
        <el-select v-model="statusValue" size="small" loading-text="Carregando...">
          <el-option
            v-for="item in options"
            :key="item.key"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24">
        <el-table
          v-loading="loading"
          :data="reports.data"
          :default-sort="{prop: 'last_closed_statement_date', order:'descending'}"
        >
          <el-table-column
            :formatter="(row) => formatDate(row.last_closed_statement_date)"
            prop="last_closed_statement_date"
            label="Data"
            align="center"
            min-width="100"
            sortable
          />
          <el-table-column prop="user.name" label="Usuário" min-width="150" align="center" sortable>
            <template slot-scope="scope">
              <span
                class="name"
                @click="handleClientNavigation(scope.row.user.id)"
              >{{ scope.row.user.name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :formatter="(row, column) => $n(row.current_remaining_amount, 'currency')"
            prop="current_remaining_amount"
            label="Valor devido"
            align="center"
            min-width="150"
            sortable
          />
          <el-table-column
            prop="days_late"
            label="Dias em atraso"
            align="center"
            min-width="160"
            sortable
          >
            <template slot-scope="scope">
              <span>{{ scope.row.days_late }} {{ scope.row.days_late !== 1 ? 'dias' : 'dia' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :lg="24" :md="12" :sm="24" :xs="24">
        <el-pagination
          :total="reports.total_pages * 10"
          :page-sizes="[10,20,30,40,50]"
          :current-page.sync="reportsPages"
          background
          layout="sizes, prev, pager, next"
          @current-change="handleReportsPagination"
          @size-change="handleReportsSizeChange"
        />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      reportsPages: 1,
      options: [
        {
          key: 0,
          value: "ok",
          label: "Em dia"
        },
        {
          key: 1,
          value: "c1",
          label: "Ciclo 1"
        },
        {
          key: 2,
          value: "c2",
          label: "Ciclo 2"
        },
        {
          key: 3,
          value: "c3",
          label: "Ciclo 3"
        },
        {
          key: 4,
          value: "c4",
          label: "Ciclo 4"
        },
        {
          key: 5,
          value: "c5",
          label: "Ciclo 5"
        }
      ],
      statusValue: "c1",
      page: 1,
      dateCustom: [],
      clientQuery: "",
      loading: true,
      reportsPageSize: 30
    };
  },
  computed: {
    ...mapGetters({
      reports: types.CHARGE_REPORTS,
      authOrigin: types.AUTH_ORIGIN
    })
  },
  watch: {
    statusValue: function(val) {
      this.handleStatusType(val);
    },
    clientQuery: function(val) {
      this.handleFilterReports(val);
    }
  },
  async mounted() {
    this.loading = true;
    const params = {};
    params.page = this.page;
    if (typeof this.$router.history.current.params.statusSeries === "string") {
      this.statusValue = this.$router.history.current.params.statusSeries;
    }
    if (this.authOrigin === "app") {
      this.statusValue = this.$router.history.current.query.cycle;
    }
    params.status = this.statusValue;
    try {
      await this.$store.dispatch(types.CHARGE_REPORTS, params);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({
        title: "Ops...",
        message: "Não foi possível recuperar os dados."
      });
    }
    this.loading = false;
  },
  methods: {
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    async handleReportsPagination(page) {
      this.loading = true;
      try {
        const params = {};
        params.page = page;
        params.status = this.statusValue;
        params.page_size = this.reportsPageSize;
        if (this.clientQuery !== "") {
          params.q = this.clientQuery;
        }
        await this.$store.dispatch(types.CHARGE_REPORTS, params);
      } catch (error) {
        error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        };
      }
      this.loading = false;
    },
    async handleReportsSizeChange(size) {
      this.loading = true;
      this.reportsPageSize = size;
      const params = {};
      params.page_size = size;
      if (this.clientQuery !== "") {
        params.q = this.clientQuery;
      }
      try {
        await this.$store.dispatch(types.CHARGE_REPORTS, params);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    async handleStatusType(status) {
      this.loading = true;
      try {
        const params = {};
        params.status = status;
        params.page_size = this.reportsPageSize;
        await this.$store.dispatch(types.CHARGE_REPORTS, params);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    async handleClientNavigation(id) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(id);
      }
      await this.$store.dispatch(types.CLIENT_RESET);
      this.$router.push({
        name: "Cliente",
        params: { clientID: id }
      });
    },
    handleFilterReports: _.debounce(async function(query) {
      this.loading = true;
      try {
        const params = {};
        params.q = query;
        params.page = 1;
        params.status = this.statusValue;
        await this.$store.dispatch(types.CHARGE_REPORTS, params);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    }, 700)
  }
};
</script>

<style lang="scss" scoped>
.charge {
  overflow-x: hidden;
  padding: 1rem;
  .el-row {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 1rem;
  }
  .name {
    cursor: pointer;
    &:hover {
      color: $laranja;
    }
  }
  &--date-picker {
    .el-col {
      @media (max-width: 767px) {
        &:nth-of-type(2) {
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-date-editor .el-range-separator {
  width: 11% !important;
}
</style>
