<template id="my-template">
  <el-card v-loading="loading" class="timeline-card">
    <h3 class="timeline--card__title">Últimos eventos</h3>
    <div class="container">
      <div class="timeline">
        <div v-for="article in timeline" :key="article.id" class="article">
          <div class="article--icon">
            <font-awesome-icon v-if="article.step.type === 'contact'" size="lg" icon="comments" />
            <font-awesome-icon v-if="article.step.type === 'deal'" size="lg" icon="file-signature" />
            <font-awesome-icon
              v-if="
                article.description === 'Contato 60 dias' &&
                  article.step.type === 'contact'
              "
              icon="exclamation-triangle"
            />
            <!-- <font-awesome-icon
                v-if="article.description === 'Contato 30 dias'"
                icon="comments"
            />-->
          </div>
          <div class="article--content">
            <span class="user-name" @click="handleNavigation(article.user)">{{ article.user.name }}</span>
            <p class="article-date">{{ formatDate(article.date) }}</p>
            <h3>
              <a :href="'#' + article.event_type" class="article-contact">
                {{
                article.step.label
                }}
              </a>
            </h3>
          </div>
        </div>
      </div>
      <el-button round size="mini" type="warning" @click="fetchMoreActivities">Ver mais</el-button>
    </div>
  </el-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faExclamationTriangle,
  faComments,
  faFileSignature
} from "@fortawesome/free-solid-svg-icons";

library.add(faExclamationTriangle, faComments, faFileSignature);

moment.locale("pt-BR");

export default {
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      initialPage: 10,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      timeline: types.STORE_CHARGING_TIMELINE,
      storeID: types.AUTH_ACTIVE_STORE
    })
  },
  async mounted() {
    this.getTimeline();
  },
  methods: {
    handleNavigation(user) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(user.id);
      }
      this.$router.push({ path: `/perfil/${user.id}` });
    },
    countAllArticles() {
      var count = 0;
      for (var date in this.searchedArticles) {
        count += this.searchedArticles[date].length;
      }
      return count;
    },
    formatDate(date) {
      return moment(date).fromNow();
    },
    getTimeline() {
      this.$store
        .dispatch(types.STORE_CHARGING_TIMELINE, {
          storeID: this.storeID,
          page: 1,
          page_size: this.initialPage
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
    },
    async fetchMoreActivities() {
      this.loading = true;
      this.initialPage += 10;
      await this.$store
        .dispatch(types.STORE_CHARGING_TIMELINE, {
          storeID: this.storeID,
          page: 1,
          page_size: this.initialPage
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
    }
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  opacity: 0;
  width: 1px;
}
.timeline-card {
  height: 700px;
  overflow: auto;
}
.timeline-card .el-card__body {
  height: 496px;
  overflow-x: auto;
}
.el-button--mini {
  margin-top: 20px;
  align-self: center;
}
/*Anulando estilizações do ElementUI */
.is-always-shadow {
  box-shadow: none !important;
  border-radius: 20px;
  border-color: #6c737c;
}

.container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.timeline {
  position: relative;
  margin-top: 10px;
}

.timeline--card__title {
  text-align: left;
  font-weight: 600;
}

.date {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  position: relative;
  left: 15px;
  margin: 15px 0;
}

.article {
  margin: 0.5rem 0;
  line-height: 1rem;
  border: 1px solid #6c737c;
  border-radius: 20px;
  padding: 1rem;
  background-color: white;
  display: flex;
}
.article--icon {
  padding: 1rem;
  width: 5rem;
}

a,
.article-date,
.article--content {
  font-size: 0.8rem;
}
a {
  color: #6c737c;
  text-decoration: none;
  font-weight: 700;
}

.article--content {
  font-family: "Montserrat", sans-serif;
  color: #6c737c;
  text-transform: capitalize;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.article-contact,
.user-name {
  cursor: pointer;
}
.article-contact:hover {
  opacity: 0.2;
}
.user-name:hover {
  opacity: 0.2;
}
</style>
