<template>
  <div v-loading="loading" class="esteira-cobranca">
    <charge-definitions />
    <div class="esteira-charts">
      <cycle-details />
      <timeline />
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import ChargeDefinitions from "@/components/Charge/Definitions";
import Timeline from "@/components/Charge/Timeline";
import CycleDetails from "@/components/Charge/CycleDetails";

export default {
  components: {
    ChargeDefinitions,
    Timeline,
    CycleDetails
  },
  data() {
    return {
      loadingHistory: true,
      ready: false,
      loading: true,
      colorArr: ["#FD9827", "#97040C", "#FC0D1B"]
    };
  },
  async created() {
    this.loading = true;
    try {
      await this.$store.dispatch(types.CHARGE_HISTORY);
      await this.$store.dispatch(types.DASHBOARD_CHARTS);
      this.loadingHistory = false;
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.loading = false;
  }
};
</script>
<style lang="scss" scoped>
.esteira-cobranca {
  padding: 0 1rem;
}
.esteira-charts {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin-bottom: 32px;
  margin-top: 1rem;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }

  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }

  h2 {
    padding: 1rem 0 2rem;
  }

  .pie {
    height: calc(100% - 75px);
  }
}
</style>
