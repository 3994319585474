<template>
  <section class="charge">
    <el-row :gutter="10">
      <el-col :lg="24" :md="12" :sm="24" :xs="24">
        <el-input
          v-model="clientQuery"
          :autofocus="true"
          size="mini"
          name="client-search"
          auto-complete="false"
          aria-autocomplete="false"
          placeholder="Buscar"
        />
      </el-col>
    </el-row>
    <el-row :gutter="10" type="flex" justify="center">
      <el-col :lg="24" :md="12" :sm="24" :xs="12">
        <el-select v-model="cycleValue" size="small" loading-text="Carregando...">
          <el-option
            v-for="item in options"
            :key="item.key"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="charge--date-picker">
      <el-col :lg="12" :sm="24" :xs="24">
        <el-radio-group v-model="currentRangeDate" size="small">
          <el-radio-button v-for="date in dates" :label="date.date" :key="date.key">{{ date.date }}</el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :lg="12" :sm="24" :xs="24">
        <el-date-picker
          ref="datePicker"
          v-model="datePickerRange"
          :default-time="['00:00:00', '23:59:59']"
          size="small"
          type="daterange"
          format="dd/MM/yyyy"
          range-separator="até"
          start-placeholder="Início"
          end-placeholder="Fim"
          align="center"
          @change="handleCustomDate"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24">
        <el-table
          v-loading="loading"
          :data="list.data"
          :default-sort="{prop: 'created_at', order:'descending'}"
        >
          <el-table-column
            :formatter="(row) => formatDate(row.created_at)"
            min-width="100"
            prop="created_at"
            label="Data"
            align="center"
            sortable
          />
          <el-table-column
            min-width="230"
            prop="charging_event.user.name"
            label="Usuário"
            align="center"
            sortable
          >
            <template slot-scope="scope">
              <span
                class="name"
                @click="handleClientNavigation(scope.row.charging_event.user.id)"
              >{{ scope.row.charging_event.user.name }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" prop="log_type" label="Tipo" align="center" sortable>
            <template slot-scope="scope">
              <i class="el-icon" />
              <span>{{ handleTypes(scope.row.log_type) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            prop="comment"
            label="Comentário"
            align="center"
            sortable
          />
          <el-table-column
            min-width="150"
            prop="charging_event.description"
            label="Descrição"
            align="center"
            sortable
          />
          <el-table-column
            min-width="180"
            prop="charging_event.step.trigger"
            label="Ciclo de cobrança"
            align="center"
            sortable
          >
            <template slot-scope="scope">
              <i class="el-icon" />
              <span>{{ handleCycleValue(scope.row.charging_event.step.trigger) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :lg="24" :md="12" :sm="24" :xs="24">
        <el-pagination
          :total="list.total_pages * 10"
          :current-page.sync="currentPage"
          background
          layout="prev, pager, next"
          @current-change="handleListPagination"
        />
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      currentPage: 1,
      options: [
        {
          key: 1,
          value: "c1",
          label: "Ciclo 1"
        },
        {
          key: 2,
          value: "c2",
          label: "Ciclo 2"
        },
        {
          key: 3,
          value: "c3",
          label: "Ciclo 3"
        },
        {
          key: 4,
          value: "c4",
          label: "Ciclo 4"
        },
        {
          key: 5,
          value: "c5",
          label: "Ciclo 5"
        }
      ],
      dates: [
        { date: "1 Mês", key: 1 },
        { date: "2 Meses", key: 60 },
        { date: "3 Meses", key: 90 }
      ],
      cycleValue: "c1",
      page: 1,
      currentRangeDate: "1 Mês",
      datePickerRange: [],
      dateCustom: [],
      clientQuery: "",
      day: null,
      month: null,
      year: null,
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      list: types.CHARGE_LIST
    })
  },
  watch: {
    cycleValue: function(val) {
      this.handleCycleType(val);
    },
    currentRangeDate: function(val) {
      this.rangeDateFilter(val);
    },
    clientQuery: function(val) {
      this.handleFilterList(val);
    }
  },
  async mounted() {
    this.loading = true;
    this.day = new Date().getDay();
    this.month = new Date().getMonth();
    this.year = new Date().getFullYear();

    if (this.month === 0) {
      this.month = 11;
      this.year = this.year - 1;
    } else {
      this.month = this.month - 1;
    }

    const initial_date = new Date(this.year, this.month, this.day);
    const end_date = new Date();
    this.datePickerRange = [initial_date, end_date];
    try {
      const params = {};
      params.page = this.page;
      params.series = this.cycleValue;
      params.initial_date = initial_date.toISOString();
      params.end_date = end_date.toISOString();
      if (this.clientQuery !== "") {
        params.q = this.clientQuery;
      }
      await this.$store.dispatch(types.CHARGE_LIST, params);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({
        title: "Ops...",
        message: "Não foi possível recuperar os dados."
      });
    }
    this.loading = false;
    this.$router.history.current.params.cycleSeries = "";
  },
  methods: {
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    handleTypes(type) {
      switch (type) {
        case "sms":
          return "Mensagem";
        case "call":
          return "Ligação";
      }
    },
    handleRangeDate() {
      const today = new Date();
      const rangeEnd = new Date(today.setHours(23, 59, 59));
      const rangeStart = new Date(today.setHours(0, 0, 0));
      let numberOfDays = "";
      switch (this.currentRangeDate) {
        case "3 Meses":
          numberOfDays = 90;
          break;
        case "2 Meses":
          numberOfDays = 60;
          break;
        case "1 Mês":
          numberOfDays = 30;
          break;
      }
      rangeStart.setTime(
        rangeStart.getTime() - 3600 * 1000 * 24 * numberOfDays
      );
      return [rangeStart, rangeEnd];
    },
    handleCycleValue(val) {
      if (val <= 30) {
        return "Ciclo 1";
      } else if (val > 30 && val <= 60) {
        return "Ciclo 2";
      } else if (val > 60 && val <= 90) {
        return "Ciclo 3";
      } else if (val > 90 && val <= 120) {
        return "Ciclo 4";
      } else {
        return "Ciclo 5";
      }
    },
    async handleListPagination(page) {
      this.loading = true;
      try {
        const params = {};
        params.page = page;
        params.series = this.cycleValue;
        params.initial_date = this.datePickerRange[0].toISOString();
        params.end_date = this.datePickerRange[1].toISOString();
        if (this.clientQuery !== "") {
          params.q = this.clientQuery;
        }
        await this.$store.dispatch(types.CHARGE_LIST, params);
      } catch (error) {
        error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        };
      }
      this.loading = false;
    },
    async handleCycleType(series) {
      this.loading = true;
      try {
        const params = {};
        params.series = series;
        params.initial_date = this.datePickerRange[0];
        params.end_date = this.datePickerRange[1];
        if (this.clientQuery !== "") {
          params.q = this.clientQuery;
        }
        await this.$store.dispatch(types.CHARGE_LIST, params);
        this.currentPage = 1;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    async rangeDateFilter(days) {
      this.loading = true;
      this.datePickerRange = this.handleRangeDate(days);
      const params = {};
      if (this.clientQuery !== "") {
        params.q = this.clientQuery;
      }
      params.series = this.cycleValue;
      params.initial_date = this.datePickerRange[0].toISOString();
      params.end_date = this.datePickerRange[1].toISOString();
      try {
        await this.$store.dispatch(types.CHARGE_LIST, params);
        this.currentPage = 1;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    async handleCustomDate() {
      this.loading = true;
      const params = {};
      if (this.clientQuery !== "") {
        params.q = this.clientQuery;
      }
      params.series = this.cycleValue;
      this.currentPage = 1;
      params.page = 1;
      if (!this.datePickerRange) {
        this.handleRangeDate();
        params.initial_date = this.datePickerRange[0].toISOString();
        params.end_date = this.datePickerRange[1].toISOString();
      } else {
        params.initial_date = this.datePickerRange[0].toISOString();
        params.end_date = this.datePickerRange[1].toISOString();
      }
      try {
        await this.$store.dispatch(types.CHARGE_LIST, params);
        this.currentPage = 1;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    async handleClientNavigation(id) {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage(id);
      }
      this.loading = true;
      await this.$store.dispatch(types.CLIENT_RESET);
      this.$router.push({
        name: "Cliente",
        params: { clientID: id }
      });
      this.loading = false;
    },
    handleFilterList: _.debounce(async function(query) {
      this.loading = true;
      try {
        const params = {};
        params.q = query;
        params.page = 1;
        params.series = this.cycleValue;
        await this.$store.dispatch(types.CHARGE_LIST, params);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    }, 700)
  }
};
</script>

<style lang="scss" scoped>
.charge {
  overflow-x: hidden;
  padding: 1rem;
  .el-row {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 1rem;
  }
  .name {
    cursor: pointer;
    &:hover {
      color: $laranja;
    }
  }
  &--date-picker {
    .el-col {
      @media (max-width: 767px) {
        &:nth-of-type(2) {
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-date-editor .el-range-separator {
  width: 11% !important;
}
.el-date-table th {
  padding: 0;
}
</style>
