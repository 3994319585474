<template>
  <el-row :gutter="20">
    <el-col
      :lg="6"
      :md="12"
      :sm="12"
      :xs="24"
      align="center">
      <div class="definition--wrapper">
        <span class="definition--title">Pagamentos realizados</span>
        <!-- eslint-disable-next-line vue/no-parsing-error -->
      </br>
        <span class="definition--value">{{ charts.current_payments }}</span>
      </div>
    </el-col>
    <el-col
      :lg="6"
      :md="12"
      :sm="12"
      :xs="24"
      align="center">
      <div class="definition--wrapper">
        <span class="definition--title">Contatos realizados</span>
        <!-- eslint-disable-next-line vue/no-parsing-error -->
      </br>
        <span class="definition--value">{{ charts.current_contacts }}</span>
      </div>
    </el-col>
    <el-col
      :lg="6"
      :md="12"
      :sm="12"
      :xs="24"
      align="center">
      <div class="definition--wrapper">
        <span class="definition--title">Reparcelamentos realizados</span>
        <!-- eslint-disable-next-line vue/no-parsing-error -->
      </br>
        <span class="definition--value">{{ charts.current_deals }}</span>
      </div>
    </el-col>
    <el-col
      :lg="6"
      :md="12"
      :sm="12"
      :xs="24"
      align="center">
      <div class="definition--wrapper">
        <span class="definition--title">Itens em cobrança</span>
        <!-- eslint-disable-next-line vue/no-parsing-error -->
      </br>
        <span class="definition--value">{{ charts.current_charging_events }}</span>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      ready: false
    };
  },
  computed: {
    ...mapGetters({
      charts: types.CHARGE_DEFINITIONS
    })
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.CHARGE_DEFINITIONS);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
};
</script>

<style lang="scss" scoped>
.el-col {
  font-weight: bold;
  padding: 0.5rem;

  .definition--wrapper{

  border-radius: 15px;
  border: 1px solid #6c737c;
  padding: 1rem;
  }
}
.definition--title {
  color: $preto;
  text-align: center;
  font-size: 0.8rem;
}
.definition--value {
  font-size: 30px;
  color: $preto;
}
</style>
