<template>
  <div class="cycle-details--wrapper">
    <div
      v-for="cycle in clientWallet"
      :key="cycle.series"
      :class="`cycle-details--block cycle-details--block-${cycle.series}`"
    >
      <span class="cycle-details--block__txt">{{ cycle.label }}</span>
      <span class="cycle-details--block__number">{{ cycle.count }}</span>
      <button class="cycle-details--block__btn" @click="handleDetails(cycle)">Ver detalhes</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      clientWallet: types.DASHBOARD_CLIENT_WALLET
    })
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.DASHBOARD_CLIENT_WALLET);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    handleDetails: async function(status) {
      try {
        if (this.authOrigin === "app") {
          return NativeAppChannel.postMessage(`status/${status.series}`);
        }
        await this.$router.push({
          name: "Clientes Pendentes",
          params: { statusSeries: status.series }
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.cycle-details--wrapper {
  border-radius: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  @media (max-width: 514px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
  .cycle-details--block {
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-weight: 900;
    &__txt {
      font-size: 2rem;
      text-align: center;
    }
    &__number {
      margin-top: 1rem;
      font-size: 3rem;
    }
    &__btn {
      margin-top: 1rem;
      border: none;
      border-radius: 30px;
      padding: 0.5rem;
      font-weight: 900;
      font-size: 0.8rem;
      cursor: pointer;
      transition: opacity;
      transition-duration: cubic-bezier(0.4, 0, 0.2, 1);
      &:hover {
        opacity: 0.2;
      }
    }
  }
  .cycle-details--block-ok {
    border-radius: 30px 0 0 0;
    background-color: #eef6e5;
    color: #5aa621;
    @media (max-width: 514px) {
      border-radius: 30px 30px 0 0;
    }
    .cycle-details--block__btn {
      background-color: #5aa621;
      color: #fff;
    }
  }
  .cycle-details--block-c1 {
    background-color: #fffbf0;
    color: #ffd16e;
    @media (max-width: 1024px) {
      border-radius: 0 30px 0 0;
    }
    @media (max-width: 514px) {
      border-radius: 0;
    }
    .cycle-details--block__btn {
      background-color: #ffd16e;
      color: #6c737c;
    }
  }
  .cycle-details--block-c2 {
    background-color: #fdf6f1;
    color: #f5a777;
    border-radius: 0 30px 0 0;
    @media (max-width: 1024px) {
      border-radius: 0;
    }
    .cycle-details--block__btn {
      background-color: #f5a777;
      color: #fff;
    }
  }
  .cycle-details--block-c3 {
    background-color: #fef3f3;
    color: #f38a8c;
    border-radius: 0 0 0 30px;
    @media (max-width: 1024px) {
      border-radius: 0;
    }
    .cycle-details--block__btn {
      background-color: #f38a8c;
      color: #fff;
    }
  }
  .cycle-details--block-c4 {
    background-color: #f7f3f9;
    color: #aa8ac1;
    @media (max-width: 1024px) {
      border-radius: 0 0 0 30px;
    }
    @media (max-width: 514px) {
      border-radius: 0;
    }
    .cycle-details--block__btn {
      background-color: #aa8ac1;
      color: #fff;
    }
  }
  .cycle-details--block-c5 {
    background-color: #f0f1f6;
    color: #7172a9;
    border-radius: 0 0 30px 0;
    @media (max-width: 514px) {
      border-radius: 0 0 30px 30px;
    }
    .cycle-details--block__btn {
      background-color: #7172a9;
      color: #fff;
    }
  }
}
</style>
